.App {
  text-align: center;
  background-color: white;
  /* stretch full height so footer margin top auto places it on bottom of page */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  overflow-x: hidden;
  position: relative;
  color: #222222;
}

.App>.content {
  padding-left: 16px;
  padding-right: 16px;
}

@media screen and (min-width: 768px) {
  .App>.content {
    padding-left: 134px;
    padding-right: 134px;
  }
}

.App>.footer {
  /* this does the actual placement of footer at bottom */
  margin-top: auto;
}
